<script>
    import isEmpty from 'lodash/isEmpty';
    import WarnBeforeLeave from '@core/mixins/WarnBeforeLeave';

    export default {
        name: 'EventReservation',
        mixins: [
            WarnBeforeLeave
        ],
        props: {
            payload: {
                type: Object,
                default: () => ( {} )
            }
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.event.theEvent'
        },
        render( ) {
            return null;
        },
        created( ) {
            if ( isEmpty( this.payload ) ) {
                this.$router.replace( { name: 'event', params: { id: this.$route.params.id } } );
            }
        },
    };
</script>